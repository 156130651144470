export const HomeMenu = [
    
    {
        title: 'Home',
        url: '#home',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: '#about',
        cName: 'nav-links'
    },
    {
        title: 'Experience',
        url: '#experience',
        cName: 'nav-links'
    },
    {
        title: 'Contact',
        url: '#contact',
        cName: 'nav-links'
    }
]