import React, { Component } from 'react';
import './nav.css';
import Me from '../../assets/ana-logo-small.svg';
import { HomeMenu } from './HomeMenu';

class Nav extends Component {
  state = {
    clicked: false,
    hidden: false,
    prevScrollPos: window.pageYOffset,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const hidden = currentScrollPos > this.state.prevScrollPos;
    this.setState({
      hidden,
      prevScrollPos: currentScrollPos,
    });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      clicked: !prevState.clicked,
      hidden: false, // Ensure that 'hidden' is always set to false when the burger menu is clicked
    }));
  };


  render() {
    const { hidden, clicked } = this.state;

    return (
      <div role="navigation" className={`nav-wrapper ${hidden && !clicked ? 'hidden' : ''}`}>
        <nav className={`nav ${clicked ? 'active' : ''}`}>
          <div className='navbar'>
            <a href="https://www.anachen.com" className="home-link" title="Scroll back to top" role="button" >
              <img src={Me} alt="Scroll back to top" className="nav-logo" />
            </a>
            <div id="menu"
              className='menu-click'
              onClick={this.handleClick}
              aria-hidden="true"
            >
              <div aria-hidden="true" className={clicked ? 'menu-icon open' : 'menu-icon'}>
                <div className='menu-icon-burger'></div>
              </div>
            </div>
          </div>
          <div className={`home-menu ${clicked ? 'active' : ''}`}>
            <ul>
              {HomeMenu.map((item, index) => (
                <li key={index}>
                  <a href={item.url} className={item.cName} role="button">
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Nav;
