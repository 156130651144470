import React from 'react'
import './about.css'
import Portrait from "../../assets/ana-portrait.jpg"

const About = () => {
  return (
    <section id="about">
    <div className="a">
        <div className="a-left">
            {/* <div className="a-card bg"></div> */}
            <div className="a-card">
                <img src={Portrait} alt="Ana Chen having coffee"  className="a-img" />
            </div>
        </div>
        <div className="a-right">
        <span className="subhead a-subhead">A little bit</span>
          <h2 className="a-title">About Me</h2>
          <div className="a-mercury">
            <div className="a-mercury-text">
              <h3 className="a-job-title">Production <span class="nowrap">Design Lead</span></h3>
              <p className="a-mercury-descr">I work at WebMD Ignite providing healthcare marketing and engagement solutions for a number of health systems and organizations across <span class="nowrap">the country.</span></p>
            </div>
          </div>
          <span className="subhead">In my freetime you can find me ...</span>
          <p className="a-freetime-descr">Learning front-end web developement. Spending time with my family and friends. Taking pictures. Freelancing. Going for runs. Camping. Finding new places to eat. Making great food.</p>

        </div>
    </div>
    </section>
  )
}

export default About