import React from 'react'
import './intro.css'
import Me from "../../assets/ana-hero.svg"
// import Resume from "../../assets/Ana-Chen-Resume-2024.pdf"


const Intro = () => {
  return (
    <div className="i" id='home' tabindex="0">
    <div className="i-2-col">
        <div className="i-left">
            <div className="i-left-wrapper">
                <h1 className="sr-only">Hi there! I'm Ana Chen, Web, UX, Graphic and Produciton Designer.</h1>
                <h2 className="i-intro" aria-hidden="true">Hi there! I’m</h2>
                <h1 className="i-name" aria-hidden="true">Ana Chen</h1>
                <div className="i-title" aria-hidden="true">
                    <div className="i-title-wrapper" >
                        <div className="i-title-item" aria-hidden="true">Web</div>
                        <div className="i-title-item" aria-hidden="true">UX & Visual</div>
                        <div className="i-title-item" aria-hidden="true">Graphic & Digital</div>
                        <div className="i-title-item" aria-hidden="true">Production</div>
                    </div>
                </div>
                <h2 className="i-intro" aria-hidden="true">Designer </h2>
                <div className="i-cta-wrapper">
                    
                    <a href="/static/media/Ana-Chen-Resume-2024.pdf"rel="noreferrer" target="_blank" aria-label="Opens in new tab" className="primary-button i-resume">Resume <i class="fa-solid fa-chevron-right"></i></a>
                    
                    < a href="#intro-contact" className="primary-button i-contact">
                    Contact <i className="fa-solid fa-chevron-right"></i>
                    </a>
                </div>
            </div>
    </div>
        <div className="i-right">
            <img src={Me} alt="Illustration of Ana working from home" className="i-img" />
        </div>
    </div>
    </div>
  )
}

export default Intro