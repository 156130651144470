import React from 'react'
import './experience.css'

const Experience = () => {
  return (
    <section id="experience">
      <span className="subhead text-center">Skills I Have</span>
      <h2 className='e-title text-center'>My Experience</h2>
      <span className='e-note text-center'>Ratings are based on a skill level of 1 to 5, <span class="nowrap">with 5 as most experience</span></span>

      <div className="container e-container">
        <div className="e-design">
          <h3>Design</h3>

          <div className="e-content">

          <article className="e-details">
            <span className="sr-only">InDesign skill level 5 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">InDesign</h4>
                <div className="plus">+++++</div>
                </div>
          </article>  

            <article className="e-details">
            <span className="sr-only">Illustrator skill level 5 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">           
                <h4 className="e-skill">Illustrator</h4>
                <div className="plus">+++++</div>
                </div>
            </article>    

            <article className="e-details">
            <span className="sr-only">Photoshop skill level 5 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">Photoshop</h4>
                <div className="plus">+++++</div>
                </div>
            </article> 

          <article className="e-details">
          <span className="sr-only">Sketch skill level 5 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">Sketch</h4>
                <div className="plus">+++++</div>
                </div>
            </article>

            <article className="e-details">
            <span className="sr-only">Google Web Designer skill level 5 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">Google Web Designer</h4>
                <div className="plus">+++++</div>
                </div>
            </article> 

            <article className="e-details">
            <span className="sr-only">Creative Hub skill level 4 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">Creative Hub</h4>
                <div className="plus">++++</div>
                </div>
            </article>

            <article className="e-details">
            <span className="sr-only">Flexitive skill level 4 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">Flexitive</h4>
                <div className="plus">++++</div>
                </div>
            </article> 
 
            <article className="e-details">
            <span className="sr-only">XD skill level 2 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">XD</h4>
                <div className="plus">++</div>
                </div>
            </article> 

            </div>
          </div>
          <div className="e-development">
          <h3>Development</h3>

          <div className="e-content">

          <article className="e-details">
          <span className="sr-only">Drupal skill level 5 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">Drupal</h4>
                <div className="plus">+++++</div>
                </div>
            </article>  

            <article className="e-details">
            <span className="sr-only">Wordpress skill level 4 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">Wordpress</h4>
                <div className="plus">++++</div>
                </div>
            </article>  
          
            <article className="e-details">
            <span className="sr-only">HTML5 skill level 4 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">HTML5</h4>
                <div className="plus">++++</div>
                </div>
            </article>  

            <article className="e-details">
            <span className="sr-only">CSS skill level 5 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">CSS</h4>
                <div className="plus">+++++</div>
                </div>
            </article>  

            <article className="e-details">
            <span className="sr-only">JavaScript skill level 3 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">JavaScript</h4>
                <div className="plus">+++</div>
                </div>
            </article>  

            <article className="e-details">
            <span className="sr-only">SASS skill level 2 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">SASS</h4>
                <div className="plus">++</div>
                </div>
            </article>  

            <article className="e-details">
            <span className="sr-only">Git and GitHub skill level 2 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill" >Git/Github</h4>
                <div className="plus">++</div>
                </div>
            </article>  

            <article className="e-details">
            <span className="sr-only">React skill level 2 out of 5</span>
              <i class="fa-solid fa-circle-check e-check" aria-hidden="true"></i>
              <div aria-hidden="true">              
                <h4 className="e-skill">React</h4>
                <div className="plus">++</div>
                </div>
            </article>  

          </div>
        </div>
      </div>
    </section>  )
}

export default Experience