import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className='f' role="footer">
    <div className="c-social-bg"></div>
    <div className="social-wrapper">
    <p className="social-text">This site was made with <i title="love" class="fa-solid fa-heart"></i> and <i class="fa-brands fa-react" title="react"></i> </p>
    <p className="social-text" aria-hidden="true">Find me on</p>
    <a className='social' href='https://dribbble.com/anachen' target="_blank" rel="noreferrer"><i class="fa-brands fa-dribbble"></i><span className="sr-only">Find me on Dribbble</span></a>&nbsp;&nbsp;&nbsp;
    <a className='social' href='https://www.linkedin.com/in/ana-chen-designer/'  target="_blank" rel="noreferrer"><i class="fa-brands fa-linkedin-in"></i><span className="sr-only">Find me on LinkedIn</span></a>

    <hr className='footer-rule'></hr>

    <p className="copyright-text">© Copyright Ana Chen 2024.</p>
</div>
</div>
  )
}

export default Footer