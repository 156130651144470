import { useRef, useState } from 'react'
import "./contact.css"
import emailjs from '@emailjs/browser'
// useContext will need to be called if adding in dark mode
// import Portfolio from "../../assets/Ana-Chen-Portfolio-2024.pdf"
// import { ThemeContext } from './context'


const Contact = () => {
    const formRef = useRef();
    const [showForm, setShowForm] = useState(true);
    const [done, setDone] = useState(false);
  
    const handleSubmit = (e) => {
      e.preventDefault();
    
      // Get form data
      const formData = new FormData(formRef.current);
    
      // Check if any field is empty
      let isValid = true;
      formData.forEach((value) => {
        if (!value.trim()) {
          isValid = false;
        }
      });
    
      if (!isValid) {
        // If any field is empty, display error message
        alert('Please fill out all fields.');
        return;
      }
    
      // Proceed with form submission
      emailjs.sendForm('service_azbc7tu', 'template_k4yzsjr', formRef.current, 'yEcOhI16w92mWyzYh')
        .then((result) => {
          console.log(result.text);
          setDone(true);
          setShowForm(false);
        })
        .catch((error) => {
          console.log(error.text);
        });
    };
  
    return (
      <section id="contact" >
        <div className="c">
          <div className="c-bg" id="intro-contact"></div>
          <div className="c-wrapper">
            {showForm ? (
              <div className="c-left">
                <h2 className="c-title">Let&rsquo;s connect!</h2>
                <div className="c-info">
                  <div className="c-info-item">
                    
                    <a href="mailto:anachen516@gmail.com" className="c-email"><i class="fa-solid fa-paper-plane"></i> anachen516@gmail.com</a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="c-left">
                    <div className="confirmation-wrapper" tabindex="0" id="form">
                    <h2 className="confirmation-message">Thanks for <span style={{ whiteSpace: 'nowrap' }}>reaching out!</span></h2>
                    <p>Your message has been successfully sent. I'll be in touch soon, in the mean time feel free to view my portfolio.</p>
                    <p>
                    < a href="/static/media/Ana-Chen-Portfolio-2024.pdf" target="_blank" rel="noreferrer" aria-label="Opens in new tab">
                        <button className="primary-button">View my portfolio <i class="fa-solid fa-chevron-right"></i></button>
                    </a>
                    </p>
                </div>
              </div>
            )}
            <div className="c-right">
              {showForm && (
                <>
                  <p className="c-description"><b>Interested in working together?</b> I&rsquo;m always open to exploring new opportunities. Send me an email or fill out the form below. Completing this form will also provide access to my digital portfolio. All fields are required.</p>
                  <form ref={formRef} onSubmit={handleSubmit}>
                    <input type="text" placeholder="Name" name="user_name" aria-label="Name"/>
                    <input type="text" placeholder="Subject" name="user_subject" aria-label="Subject"/>
                    <input type="text" placeholder="Email" name="user_email" aria-label="Email"/>
                    <textarea rows="5" placeholder="Message" name="message" aria-label="Message"/>
                    <button className="primary-button" aria-label="Submit">Submit <i class="fa-solid fa-chevron-right"></i></button>
                  </form>
                </>
              )}
              {done && !showForm}
            </div>
          </div>
        </div>
      </section>
    );
  };
  

export default Contact